define([], () => {
  const dateSelector = () => {
    const component = {};

    component.selectedDay = 0o1;
    component.selectedMonth = null;
    component.selectedYear = null;

    component.allDayUnits = [];
    component.allMonthUnits = [];
    component.allYearUnits = [];

    const _config = {
      selectors: {
        dayInputElement: '[data-input-day]',
        monthInputElement: '[data-input-month]',
        yearInputElement: '[data-input-year]',
        errorMessageElement: '[data-error-message]',
        useStringMonths: 'data-use-string-months',
        stringMonthsArray: 'data-string-months-array',
        oldestYear: 'data-oldest-year',
        yearsAscendingOrder: 'data-years-ascending-order'
      },
      timeUnits: {
        defaultDayUnits: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
        defaultMonthUnitsInts: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        defaultMonthUnitsStings: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      },
      defaultOldestYear: 1920
    };

    const _init = (element) => {
      component.element = element;
      const dayElement = component.element.querySelector(component.config.selectors.dayInputElement);
      const monthElement = component.element.querySelector(component.config.selectors.monthInputElement);
      const yearElement = component.element.querySelector(component.config.selectors.yearInputElement);

      monthElement.addEventListener('change', component.monthChangeListener);
      yearElement.addEventListener('change', component.yearChangeListener);

      component.populateTimeUnits();
      component.populateSelectElements(monthElement, component.allMonthUnits, true);
      component.populateSelectElements(yearElement, component.allYearUnits);
      component.checkDaySelectorIsPresent();

      return component;
    };

    const _checkDaySelectorIsPresent = () => {

      const dayElement = component.element.querySelector(component.config.selectors.dayInputElement);

      if (document.querySelector(component.config.selectors.dayInputElement)) {
        component.populateSelectElements(dayElement, component.allDayUnits);
        dayElement.addEventListener('change', component.dayChangeListener);
      }
    };

    const _dayChangeListener = (e) => {
      component.selectedDay = e.target.options[e.target.selectedIndex].value;
      component.validateDateInput();
    };

    const _monthChangeListener = (e) => {
      component.selectedMonth = e.target.options[e.target.selectedIndex].value;
      component.validateDateInput();
    };

    const _yearChangeListener = (e) => {
      component.selectedYear = e.target.options[e.target.selectedIndex].value;
      component.validateDateInput();
    };

    const _populateTimeUnits = () => {
      const currentTime = new Date();
      const currentYear = currentTime.getFullYear();
      if(component.element.getAttribute('data-oldest-year')) {
        component.config.defaultOldestYear = parseInt(component.element.getAttribute('data-oldest-year'));
      }

      let y = component.config.defaultOldestYear;
      component.allYearUnits = [];
      while (y <= currentYear) {
        component.allYearUnits.push(y);
        y++;
      }

      if(component.element.hasAttribute(component.config.selectors.useStringMonths)) {
        component.allMonthUnits = component.config.timeUnits.stringMonthsArray &&
                                component.config.timeUnits.stringMonthsArray.length > 0 ?
          component.config.timeUnits.stringMonthsArray :
          component.config.timeUnits.defaultMonthUnitsStings;
      } else {
        component.allMonthUnits = component.config.timeUnits.defaultMonthUnitsInts;
      }

      component.allDayUnits = component.config.timeUnits.defaultDayUnits;

      if(!component.element.hasAttribute(component.config.selectors.yearsAscendingOrder)) {
        component.allYearUnits = component.allYearUnits.reverse();
      }
    };

    const _populateSelectElements = (selectElement, values, isMonthElement) => {
      for (let i = 0; i < values.length; i++){
        let option = document.createElement('option');
        if(isMonthElement && component.element.hasAttribute(component.config.selectors.useStringMonths)) {
          option.value = component.allMonthUnits[i];
        } else {
          option.value = values[i];
        }
        option.innerHTML = values[i];
        selectElement.appendChild(option);
      }
    };

    const _validateDateInput = () => {
      if(!component.selectedDay || !component.selectedMonth || !component.selectedYear) {
        return;
      }
      if(!component.isValidDate(component.selectedDay, component.selectedMonth, component.selectedYear)) {
        component.showErrorMessage();
      } else {
        component.hideErrorMessage();
      }
    };

    const _isValidDate = (day, month, year) => {
      let givenDate = new Date(year, month, day);
      return !isNaN(givenDate.getTime()) && component.daysInMonth(month, year) >= day;
    };

    const _daysInMonth = (month, year) => {
      return new Date(year, month, 0).getDate();
    };

    const _showErrorMessage = () => {
      const errorMessageElement = component.element.querySelector(component.config.selectors.errorMessageElement);
      errorMessageElement.classList.add('show');
    };

    const _hideErrorMessage = () => {
      const errorMessageElement = component.element.querySelector(component.config.selectors.errorMessageElement);
      errorMessageElement.classList.remove('show');
    };

    const _allDatesSelectedAndValid = () => {
      return component.selectedDay
        && component.selectedMonth
        && component.selectedYear
        && component.isValidDate(component.selectedDay, component.selectedMonth, component.selectedYear)
    };

    component.init = _init;
    component.checkDaySelectorIsPresent = _checkDaySelectorIsPresent;
    component.dayChangeListener = _dayChangeListener;
    component.monthChangeListener = _monthChangeListener;
    component.yearChangeListener = _yearChangeListener;
    component.populateTimeUnits = _populateTimeUnits;
    component.daysInMonth = _daysInMonth;
    component.isValidDate = _isValidDate;
    component.validateDateInput = _validateDateInput;
    component.populateSelectElements = _populateSelectElements;
    component.showErrorMessage = _showErrorMessage;
    component.hideErrorMessage = _hideErrorMessage;
    component.allDatesSelectedAndValid = _allDatesSelectedAndValid;
    component.config = _config;

    return component;
  };

  return dateSelector;
});
